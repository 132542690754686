@import './material-icons/material-icons.css';

/* SansaPro */

@font-face {
  font-family: 'SansaPro';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local('SansaPro Light'), local('SansaPro-Light'),
      url('./fonts/SansaPro/SansaPro-Light.woff2') format('woff2')
}

@font-face {
  font-family: 'SansaPro';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('SansaPro Regular'), local('SansaPro-Regular'),
      url('./fonts/SansaPro/SansaPro-Regular.woff2') format('woff2')
}

@font-face {
  font-family: 'SansaPro';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local('SansaPro SemiBold'), local('SansaPro-SemiBold'),
      url('./fonts/SansaPro/SansaPro-SemiBold.woff2') format('woff2')
}

@font-face {
  font-family: 'SansaPro';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('SansaPro Bold'), local('SansaPro-Bold'),
      url('./fonts/SansaPro/SansaPro-Bold.woff2') format('woff2')
}

@font-face {
  font-family: 'SansaPro';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: local('SansaPro Black'), local('SansaPro-Black'),
      url('./fonts/SansaPro/SansaPro-Black.woff2') format('woff2')
}

/* TTTravels */

@font-face {
  font-family: 'TTTravels';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: local('TT Travels Thin'), local('TT Travels-Thin'),
      url('./fonts/TTTravels/TT Travels Trial Thin.otf')
}

@font-face {
  font-family: 'TTTravels';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: local('TT Travels ExtraLight'), local('TT Travels-ExtraLight'),
      url('./fonts/TTTravels/TT Travels Trial ExtraLight.otf')
}

@font-face {
  font-family: 'TTTravels';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local('TT Travels Light'), local('TT Travels-Light'),
      url('./fonts/TTTravels/TT Travels Trial Light.otf')
}

@font-face {
  font-family: 'TTTravels';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('TT Travels Regular'), local('TT Travels-Regular'),
      url('./fonts/TTTravels/TT Travels Trial Regular.otf')
}

@font-face {
  font-family: 'TTTravels';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local('TT Travels Medium'), local('TT Travels-Medium'),
      url('./fonts/TTTravels/TT Travels Trial Medium.otf')
}

@font-face {
  font-family: 'TTTravels';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local('TT Travels DemiBold'), local('TT Travels-DemiBold'),
      url('./fonts/TTTravels/TT Travels Trial DemiBold.otf')
}

@font-face {
  font-family: 'TTTravels';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('TT Travels Bold'), local('TT Travels-Bold'),
      url('./fonts/TTTravels/TT Travels Trial Bold.otf')
}

@font-face {
  font-family: 'TTTravels';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: local('TT Travels ExtraBold'), local('TT Travels-ExtraBold'),
      url('./fonts/TTTravels/TT Travels Trial ExtraBold.otf')
}

@font-face {
  font-family: 'TTTravels';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: local('TT Travels Trial Black'), local('TT Travels Trial Black'),
      url('./fonts/TTTravels/TT Travels Trial Black.otf')
}
